<template>
    <div>
        <div>
            <!-- <artists-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter" :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" @filter="fetchAdminLogList" /> -->

            <b-card no-body class="mb-0">
                <div class="m-2">
                    <b-row>
                        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                            <label>Show</label>
                            <b-form-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @change="changeAdminRoles({ page: 1, itemsPerPage: $event })" />
                            <label>entries</label>
                        </b-col>
                    </b-row>
                </div>

                <div class="">
                    <b-table ref="refUserListTable" class="position-relative pb-3" :items="totalPaymentList" responsive :fields="totalPaymentTableColumns" :per-page="perPage" primary-key="id" show-empty empty-text="No matching records found">
                        <template #cell(causer)="data">
                            <div class="text-center" v-if="data.item.causer != null">
                                <b-avatar size="32" v-if="data.item.causer" :src="data.item.causer.thumbnail" :text="avatarText(data.item.causer.name)" :to="{ name: 'apps-artists-view', params: { id: data.item.causer.id } }" />
                                <b-link v-if="data.item.causer != null" :to="{ name: 'apps-wallet-total-payments-detail', params: { id: data.item.causer.id } }" class="font-weight-bold d-block text-nowrap">
                                    {{ data.item.causer.name }}
                                </b-link>
                            </div>
                        </template>
                        <template #cell(related_user)="data">
                            <div class="text-left" v-if="data.item.user">
                                <b-avatar size="32" :src="data.item.user.thumbnail" :text="avatarText(data.item.user.name)" :to="{ name: 'apps-artists-view', params: { id: data.item.user.id } }" />
                                <b-link :to="{ name: 'apps-artists-view', params: { id: data.item.user.id } }" class="font-weight-bold d-block text-nowrap">
                                    {{ data.item.user.name }}
                                </b-link>
                            </div>
                        </template>
                        <template #cell(detail)="data">
                            <b-button variant="outline-info" :to="{ name: 'apps-wallet-total-payments-detail', params: { id: data.item.id } }"> Details </b-button>
                        </template>
                        <template #cell(payment_status)="data">
                            <b-badge variant="success" class="badge-glow"> {{ data.item.payment_status }} </b-badge>
                        </template>
                        <template #cell(amount)="data">
                            <span class="text-info">
                                {{ data.item.amount }}
                            </span>
                        </template>
                        <template #cell(payment_method)="data">
                            <b-badge variant="secondary" class="badge-glow">
                                {{ data.item.payment_method }}
                            </b-badge>
                        </template>
                    </b-table>
                    <div class="mx-2 mb-2">
                        <b-row class="justify-content-center">
                            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                <span class="text-muted">Showing {{ pagination.pageStart }} to {{ pagination.pageStop }} of {{ pagination.itemsLength }} entries</span>
                            </b-col>
                            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                                <b-pagination v-model="currentPage" :total-rows="pagination.itemsLength" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changeAdminRoles({ page: $event, itemsPerPage: perPage })">
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BFormSelect, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination } from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import ArtistRepository from "@/abstraction/repository/artistRepository";
// import ArtistsListFilters from "./ArtistsListFilters";
import useWalletList from "../useWalletList";

const artistRepository = new ArtistRepository();

export default {
    components: {
        // ArtistsListFilters,

        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
    },
    setup() {
        const tableType = ref("");
        const USER_APP_STORE_MODULE_NAME = "app-user";
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        });

        const roleOptions = [
            { label: "Admin", value: "admin" },
            { label: "Author", value: "author" },
            { label: "Editor", value: "editor" },
            { label: "Maintainer", value: "maintainer" },
            { label: "Subscriber", value: "subscriber" },
        ];

        const planOptions = [
            { label: "Basic", value: "basic" },
            { label: "Company", value: "company" },
            { label: "Enterprise", value: "enterprise" },
            { label: "Team", value: "team" },
        ];

        const statusOptions = [
            { label: "Pending", value: "pending" },
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inactive" },
        ];

        const {
            fetchTotalPaymentList,
            totalPaymentTableColumns,
            perPage,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            totalPaymentList,
            pagination,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        } = useWalletList();

        onMounted(async () => {
            await fetchTotalPaymentList().then((res) => {
                console.log(totalPaymentList.value);
            });
        });
        const changeAdminRoles = async (paginate) => {
            await fetchTotalPaymentList(paginate);
            await window.scrollTo(0, 0);
        };
        return {
            changeAdminRoles,
            totalPaymentList,
            pagination,
            fetchTotalPaymentList,
            totalPaymentTableColumns,
            perPage,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,

            // Filter
            avatarText,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,

            roleOptions,
            planOptions,
            statusOptions,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        };
    },
    methods: {
        async deleteArtist(artistId) {
            await artistRepository.delete(artistId);
            const index = this.totalPaymentList.findIndex((x) => x.id === artistId);
            this.$delete(this.totalPaymentList, index);
        },
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
