<template>
    <div class="container">
        <b-row class="justify-content-center" v-if="totals">
            <b-col cols="5" class="text-center">
                <b-card>
                    <h4>Total bonuses</h4>

                    <span class="text-success total-data">{{ totals.total_bonus }}</span>
                </b-card>
            </b-col>
            <b-col cols="5" class="text-center">
                <b-card>
                    <h4>Total wallet balance</h4>

                    <span class="text-warning total-data1">{{ totals.total_wallets_balance }}</span>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="justify-content-center">
            <b-col cols="10">
                <b-card class="text-center">
                    <h3>General reports</h3>
                    <b-row class="justify-content-center">
                        <b-col cols="5" class="text-center">
                            <h4 class="text-dark font-weight-bolder">
                                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="flat-danger" @click="tableType = 'payment'">
                                    <span class="text-danger font-weight-bolder list-notes">Payments<feather-icon size="24" icon="ChevronsRightIcon" class="ml-1" /></span>
                                </b-button>
                            </h4>
                        </b-col>
                        <b-col cols="5" class="text-center">
                            <h4 class="text-dark font-weight-bolder">
                                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="flat-danger" @click="tableType = 'transaction'">
                                    <span class="text-danger font-weight-bolder list-notes">transactions<feather-icon size="24" icon="ChevronsRightIcon" class="ml-1" /></span>
                                </b-button>
                            </h4>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row v-if="tableType === 'payment'">
            <b-col cols="12">
                <b-card>
                    <totalPaymentList />
                </b-card>
            </b-col>
        </b-row>
        <b-row v-if="tableType === 'transaction'">
            <b-col cols="12">
                <b-card>
                    <totalTransactionList />
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BAvatar, BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BFormRadioGroup, BFormCheckbox, BBadge } from "bootstrap-vue";
import { ref, onUnmounted, onMounted, watch } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import useWalletList from "./useWalletList";
import totalPaymentList from "./components/totalPaymentList.vue";
import totalTransactionList from "./components/totalTransactionList.vue";
export default {
    components: {
        BBadge,
        BAvatar,
        BTab,
        BFormInput,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        BFormRadioGroup,
        BFormCheckbox,
        totalPaymentList,
        totalTransactionList,
    },
    directives: {
        Ripple,
    },
    setup() {
        const tableType = ref("");
        const totals = ref();
        const { fetchTotalsData } = useWalletList();
        fetchTotalsData().then((res) => {
            totals.value = res.data.totals;
            console.log(totals.value);
        });
        return {
            totals,
            tableType,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.wallet-balance {
    font-size: 50px;
    margin-top: 60px;
}
.total-data {
    font-size: 50px;
    font-weight: 600;
    text-shadow: 1px 1px 6px rgb(0, 255, 26);
}
.total-data1 {
    font-size: 50px;
    font-weight: 600;
    text-shadow: 1px 1px 6px rgb(255, 251, 0);
}
.list-notes {
    font-size: 30px;
    text-shadow: 1px 1px 2px rgb(0, 0, 0);
}
.feather {
    font-size: 18px;
}
.card-bg {
    background-color: black;
}
</style>
